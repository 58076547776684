import { Popover } from '@mui/material';
import styled from 'styled-components';

export const SectionContainer = styled.div`
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border: 1px solid #e2e4e6;
  margin: 0;
  background-color: #fff;

  &:hover {
    .sectionHeader {
      background-color: #e8e8e8;
    }
  }
`;

export const Header = styled.div<{ $expanded: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: ${({ $expanded }) => ($expanded ? '1px solid #e2e4e6' : 'none')};

  padding: 16px;
  cursor: pointer;
`;

export const SectionInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 90%;

  h1 {
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  p {
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const SectionLogic = styled.div`
  display: flex;
  width: 275px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #e2e4e6;
  border-top: none;
  background: #fff;
  justify-content: space-around;
  margin-bottom: 16px;
  p {
    margin: 0;
  }
`;

export const StyledPopover = styled(Popover)`
  box-shadow: none;
  border: none;
  border-radius: 8px;
`;
