import { styled } from 'styled-components';

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background: #fff;
  border-left: 1px solid #e2e4e6;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  width: 100%;
  align-items: center;
  padding: 0 12px;
  min-height: 40px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const OptionTitle = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  margin: 0;
  padding: 0;
`;
