import React from 'react';

import { MenuItem, SelectChangeEvent } from '@mui/material';

import { InfoOutlined } from '@mui/icons-material';

import { StyledSelect, StyledTextField } from 'components/Input/styles';

import { ProtectorType } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import { FieldContainer, Row } from './styles';
import { getProtectorType, possibleProtectorTypesForQuestions } from './utils';
import { EditFieldProps } from './types';
import EditComponentForProtectorType from '../EditComponentForProtectorType/EditComponentForProtectorType';
import FieldFooter from './components/FieldFooter';

const EditField: React.FC<EditFieldProps> = ({
  description,
  questionType,
  lookup,
  properties,
  sectionId,
  fieldId,
  onChangeFieldProperties,
}): React.ReactElement => {
  const {
    session: { user },
    formBuilder,
  } = useStore();

  const onChangeQuestionType = (e: SelectChangeEvent<unknown>): void => {
    const newType = e.target.value as ProtectorType;
    onChangeFieldProperties(newType, 'questionType');

    // if the question type is changed to single choice or multiple choice, add the default properties
    if (newType === ProtectorType.MultiChoice || newType === ProtectorType.SingleChoice) {
      onChangeFieldProperties(
        {
          enum: [
            {
              key: formBuilder.getNextContainerId(),
              value: 'Option 1',
            },
          ],
        },
        'properties'
      );
    }
  };

  return (
    <FieldContainer>
      <Row>
        <StyledTextField
          fullWidth
          size="small"
          style={{ backgroundColor: '#fff' }}
          defaultValue={description || ''}
          onChange={(e) => onChangeFieldProperties(e.target.value, 'description')}
          placeholder="Description (Optional)"
          sx={{
            '.MuiInputBase-input': {
              fontSize: '14px !important',
            },
          }}
        />
        <StyledSelect
          fullWidth
          placeholder="Choose an Option"
          value={questionType === ProtectorType.SingleChoice ? ProtectorType.MultiChoice : questionType}
          onChange={onChangeQuestionType}
          $withoutPadding
          style={{
            height: 'auto',
            backgroundColor: '#fff',
            padding: '8px 14px',
          }}
          sx={{
            '.MuiSelect-select': {
              gap: '8px',
            },
            '.MuiInputBase-input': {
              fontSize: '14px !important',
            },
          }}
        >
          {possibleProtectorTypesForQuestions.map((type) => {
            const { displayName, icon } = getProtectorType(type);
            return (
              <MenuItem
                key={type}
                value={type}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                {icon}
                {displayName}
              </MenuItem>
            );
          })}
        </StyledSelect>
      </Row>
      <Row style={{ background: '#fff' }}>
        <EditComponentForProtectorType
          questionType={questionType as ProtectorType}
          activeEntityModules={user?.activeEntityModules}
          lookupData={lookup}
          properties={properties}
          onSettingLookup={(val) => onChangeFieldProperties(val, 'lookupType')}
          onSettingProperties={(val) => onChangeFieldProperties(val, 'properties')}
        />
      </Row>

      {questionType === ProtectorType.Lookup && (
        <Row style={{ background: '#fff' }}>
          <InfoOutlined htmlColor="rgba(0, 0, 0, 0.54)" fontSize="small" />
          <p style={{ margin: 0, padding: 0, color: 'rgba(0, 0, 0, 0.60)' }}>
            Lookup fields allow users to relate this event with records from another module.
          </p>
        </Row>
      )}
      <FieldFooter
        questionType={questionType}
        sectionId={sectionId}
        fieldId={fieldId}
        onChangeFieldProperties={onChangeFieldProperties}
      />
    </FieldContainer>
  );
};

export default EditField;
